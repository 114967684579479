import {
    CaretRightOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    EyeOutlined,
    InfoCircleFilled
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_LIGHT_GREEN_CLASS,
    GRAY,
    LIGHT_GREEN,
    LIVE_DEAD_FISH_COUNTING_PAGE,
    ORANGE
} from 'common/constants';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setDeadFishCountingStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getDeadFishCountingEngineStatus } from 'redux/thunks';

const SiteStatus = ({ site = {}, onPenListOpen = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (site.hasEngineBaseUrl) {
            dispatch(
                getDeadFishCountingEngineStatus({
                    baseUrl: site.engineBaseUrl,
                    siteId: site.id
                })
            );
        }
    }, [site.engineBaseUrl]);

    const handlerStartButtonClick = () => {
        onPenListOpen(site);
    };

    const handleViewButtonClick = () => {
        dispatch(
            setDeadFishCountingStreaming({
                isCounting: true,
                engineBaseUrl: site.engineBaseUrl,
                siteId: site.id,
                siteName: site.siteName,
                localityNumber: site.localityNumber,
                penNumber: site.penNumber
            })
        );
        navigate(LIVE_DEAD_FISH_COUNTING_PAGE);
    };

    const getSiteStyle = () => {
        if (site.isCounting) {
            return {
                borderColor: 'border-blue-500',
                icon: <Spin />,
                status: `${t('liveDeadFishCounting.isCounting')} ${site.penNumber ?? ''}`,
                button: (
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        icon={<EyeOutlined />}
                        onClick={() => handleViewButtonClick()}
                    >
                        {t('button.view')}
                    </Button>
                )
            };
        }

        if (site.hasEngineBaseUrl) {
            return {
                borderColor: 'border-green-600',
                icon: <CheckCircleFilled style={{ fontSize: '18px', color: LIGHT_GREEN }} />,
                status: t('liveDeadFishCounting.readyToStart'),
                button: (
                    <Button
                        className={BUTTON_LIGHT_GREEN_CLASS}
                        icon={<CaretRightOutlined />}
                        onClick={() => handlerStartButtonClick()}
                    >
                        {t('button.start')}
                    </Button>
                )
            };
        }

        if (site.hasEngineConfig) {
            return {
                borderColor: 'border-orange-500',
                icon: <InfoCircleFilled style={{ fontSize: '18px', color: ORANGE }} />,
                status: t('liveDeadFishCounting.noEngineBaseUrl')
            };
        }

        return {
            borderColor: 'border-gray-500',
            icon: <CloseCircleFilled style={{ fontSize: '18px', color: GRAY }} />,
            status: t('liveDeadFishCounting.noEngineConfig')
        };
    };

    return (
        <Spin spinning={site.loading || false}>
            <div
                className={`border border-solid rounded p-2 bg-white w-48 h-36 ${
                    getSiteStyle().borderColor
                }`}
            >
                <div className="flex items-center">
                    {getSiteStyle().icon}
                    <span className="font-bold ml-2 truncate">{site.siteName}</span>
                </div>
                <p className="font-medium mt-2">{getSiteStyle().status}</p>

                <div className="flex justify-around">{getSiteStyle().button}</div>
            </div>
        </Spin>
    );
};

SiteStatus.propTypes = {
    site: propTypes.object,
    onPenListOpen: propTypes.func
};

export default SiteStatus;
