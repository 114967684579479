import { ArrowLeftOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_RED_CLASS,
    DEAD_FISH_COUNTING_ENGINE_STREAMING_RESULT_API,
    LIGHT_GRAY,
    LIVE_FISH_EVENT_PAGE
} from 'common/constants';
import { alertErrorMessage, formatSeconds } from 'common/utils';
import { EventSourcePolyfill } from 'event-source-polyfill';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deadFishCountingStreamingSelector } from 'redux/selector';
import { setDeadFishCountingStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { stopDeadFishCountingEngine } from 'redux/thunks';
import { AUTH_SERVICE, NOTIFICATION_SERVICE } from 'services';
import Swal from 'sweetalert2';

const LiveDeadFishCounting = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const eventSourceRef = useRef();

    const streaming = useSelector(deadFishCountingStreamingSelector);
    const [streamingDeadFishCount, setStreamingDeadFishCount] = useState({});
    const [loading, setLoading] = useState(false);

    const handleStreamingMessage = useCallback((e) => {
        const streamingData = JSON.parse(e.data);

        if (!streamingData.counting) {
            handleDeadFishCountingEngineStop();
            eventSourceRef.current.close();
            return;
        }

        setStreamingDeadFishCount((prevState) => ({
            ...prevState,
            laks: streamingData?.laks,
            berggylt: streamingData?.berggylt,
            rognkjeks: streamingData?.rognkjeks,
            countingTime: streamingData?.countingTime
        }));
    }, []);

    useEffect(() => {
        const startStreaming = async () => {
            if (eventSourceRef) {
                eventSourceRef.current = await getStreamingDeadFishCount();
                eventSourceRef.current.onmessage = handleStreamingMessage;
            }
        };
        startStreaming();

        return () => {
            if (eventSourceRef?.current) {
                eventSourceRef.current.close();
            }
        };
    }, [handleStreamingMessage]);

    const getStreamingDeadFishCount = async () => {
        const streamingStatusAPI =
            streaming.engineBaseUrl + DEAD_FISH_COUNTING_ENGINE_STREAMING_RESULT_API;

        const token = await AUTH_SERVICE.getToken();
        const headers = {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'text/event-stream'
        };

        return new EventSourcePolyfill(streamingStatusAPI, { headers });
    };

    const handleBackButtonClick = () => {
        navigate(LIVE_FISH_EVENT_PAGE);
    };

    const handleDeadFishCountingEngineStop = () => {
        NOTIFICATION_SERVICE.pushNotification({
            title: t('liveDeadFishCounting.notification.stopCounting'),
            duration: 10
        });

        clearInterval(eventSourceRef.current);

        dispatch(
            setDeadFishCountingStreaming({
                isCounting: false
            })
        );
    };

    const handleStopButtonClick = () => {
        Swal.fire({
            title: t('liveDeadFishCounting.stopConfirmation'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('button.yes'),
            cancelButtonText: t('button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    await dispatch(
                        stopDeadFishCountingEngine({
                            baseUrl: streaming.engineBaseUrl,
                            penNumber: streaming.penNumber,
                            siteName: streaming.siteName,
                            localityNumber: streaming.localityNumber
                        })
                    ).unwrap();
                } catch (error) {
                    alertErrorMessage(error);
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    if (!streaming.isCounting && !streaming.engineBaseUrl) {
        return (
            <>
                <h2>{t('liveDeadFishCounting.notFound')}</h2>
                <Button
                    className={BUTTON_BLUE_CLASS}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleBackButtonClick()}
                >
                    {t('button.back')}
                </Button>
            </>
        );
    }

    return (
        <>
            <div className="flex flex-col items-center text-center">
                <h2>
                    {streaming.isCounting && <Spin className="mr-2" />}
                    <span>{`${streaming.siteName ?? ''} - ${
                        streaming.isCounting
                            ? t('liveDeadFishCounting.progress')
                            : t('liveDeadFishCounting.result')
                    } ${streaming.penNumber ?? ''}`}</span>
                </h2>

                <h2>{formatSeconds(streamingDeadFishCount.countingTime ?? 0)}</h2>
            </div>

            <Spin spinning={loading}>
                <div className="flex justify-center">
                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {streamingDeadFishCount?.laks ?? 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 text-center m-0">
                            {t('general.fishMortality.productionFish')}
                        </p>
                    </div>

                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {streamingDeadFishCount.berggylt ?? 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 m-0">
                            {t('general.fishMortality.berggylt')}
                        </p>
                    </div>

                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {streamingDeadFishCount.rognkjeks ?? 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 m-0">
                            {t('general.fishMortality.rognkjeks')}
                        </p>
                    </div>
                </div>
            </Spin>

            <div className="mt-5 flex justify-center gap-10">
                <Button
                    className={BUTTON_BLUE_CLASS}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleBackButtonClick()}
                >
                    {t('button.back')}
                </Button>
                <Button
                    className={BUTTON_RED_CLASS}
                    icon={<StopOutlined />}
                    onClick={() => handleStopButtonClick()}
                    disabled={!streaming.isCounting}
                >
                    {t('button.stop')}
                </Button>
            </div>
        </>
    );
};

export default LiveDeadFishCounting;
