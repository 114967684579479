// api public
export const ENGINE_BY_LOCALITY_NO_API = 'api/public/engine-by-locality-number';
export const SERVICE_API = 'api/public/goodeye/services';
export const AVAILABLE_TENANT_API = 'api/public/available-tenants';

// media api
export const MEDIA_MANAGEMENT_API = 'api/media';

export const USER_API = 'api/users';
export const GUEST_USER_INVITATION_API = 'api/users/invite-guest-users';

export const PROFILE_API = 'api/me/profile';

export const SITES_API = 'api/sites';
export const SITES_CONTAINING_PENS_API = 'api/sites/list';

export const PEN_API = 'api/pens';

export const FISH_GROUP_API = 'api/fish-groups';

export const OTHER_DEAD_FISH_COUNT_API = 'api/other-dead-fish-detections';

export const EXPORT_DEAD_FISH_COUNTING_REPORT_API = 'api/exports/dead-fish-counting';
export const EXPORT_DAILY_REPORT_API = 'api/exports/daily';

// notification api endpoints
export const NOTIFICATION_API = 'api/notifications';
export const COMPANY_NOTIFICATION_API = 'api/notifications/companies';
export const SITE_NOTIFICATION_API = 'api/notifications/sites';
export const PEN_NOTIFICATION_API = 'api/notifications/pens';

// api endpoint for selection
export const SITE_SELECTION_API = 'api/selection/sites';
export const PEN_SELECTION_API = 'api/selection/pens';
export const ORIGINAL_SITE_SELECTION_API = 'api/selection/original-sites';
export const ENGINE_SITE_SELECTION_API = 'api/selection/engine-sites';
export const ENGINE_BOAT_SELECTION_API = 'api/selection/engine-boats';
export const FISHWELL_FISH_ID_SELECTION_API = 'api/selection/fishwell-fish-ids';
export const SERVICE_BOAT_SELECTION_API = 'api/selection/service-boats';

// api endpoint for daily mortality
export const DAILY_MORTALITY_API = 'api/reports/daily';

// api endpoint for daily event
export const DAILY_EVENT_API = 'api/stress-detection-reports/daily-event';

// api endpoint for daily stock count
export const STOCK_COUNT_API = 'api/reports/stock-count';

// api endpoint for dead fish counting mortality
export const DEAD_FISH_COUNTING_MORTALITY_API = 'api/reports/dead-fish-counting-mortality';

// api endpoint for dead fish mortality
export const DEAD_FISH_MORTALITY_API = 'api/reports/dead-fish-mortality';

// api endpoint for fish group mortality
export const FISH_GROUP_MORTALITY_API = 'api/reports/fish-group-mortality';

// api endpoint for fish mortality card
export const DEAD_FISH_MORTALITY_CARD_API = 'api/reports/fish-mortality-card';

// api endpoint for dead fish counting
export const REPORT_TABLE_API = 'api/reports/dead-fish-counting';

// api endpoint for salmon with wound and without wound
export const SALMON_WOUND_API = 'api/reports/salmon-wound';

// api endpoint for production wound type report
export const WOUND_TYPE_DEAD_FISH_COUNT_REPORT_API = 'api/reports/wound-type-dead-fish-count';
export const WOUND_TYPE_STOCK_COUNT_REPORT_API = 'api/reports/wound-type-stock-count';

// api endpoint for event chart
export const EVENT_CHART_API = 'api/stress-detection-reports/event-chart';

// api endpoint for mortality
export const MORTALITY_API = 'api/dead-fish-detections';

// api endpoint for event
export const EVENT_LIST_API = 'api/events/list';
export const EVENT_DETAIL_API = 'api/events';

// api endpoint for scoring event
export const SCORING_EVENT_API = 'api/scoring-events';

// api endpoint for setup account
export const SETUP_ACCOUNT_API = 'api/app/setup-account';

// api endpoint for start/stop engine counting
export const ENGINE_API_START_URL = 'startstop/start';
export const ENGINE_API_STOP_URL = 'startstop/stop';
export const ENGINE_STATUS_URL = 'cstatus';
export const ENGINE_START_STOP_SITE_URL = 'api/sites/engine-start-stop-site-list';

export const SITE_COMPARISON_API = 'api/comparisons/sites';
export const PEN_COMPARISON_API = 'api/comparisons/pens';

// api endpoint for stress analysis
export const STRESS_ANALYSIS_FOR_EVENT_API = 'api/stress-analyses/events';
export const STRESS_ANALYSIS_FOR_FISH_API = 'api/stress-analyses/fishes';
export const STRESS_ANALYSIS_FOR_STRESS_LEVEL_PER_THROW_API =
    'api/stress-analyses/stress-levels-per-throw';
export const STRESS_ANALYSIS_FOR_CROWDING_TIME_PER_THROW_API =
    'api/stress-analyses/crowding-times-per-throw';
export const STRESS_ANALYSIS_FOR_STRESS_ZONE_PER_THROW_API =
    'api/stress-analyses/stress-zones-per-throw';
export const STRESS_ANALYSIS_FOR_OXYGEN_LEVEL_PER_THROW_API =
    'api/stress-analyses/oxygen-levels-per-throw';
export const STRESS_ANALYSIS_FOR_MORTALITY_PER_EVENT_API =
    'api/stress-analyses/mortalities-per-event';
export const STRESS_ANALYSIS_CORRELATION_TYPE_API = 'api/stress-analyses/correlation-types';
export const STRESS_ANALYSIS_FOR_REGRESSION_API = 'api/stress-analyses/regressions';
export const STRESS_ANALYSIS_FOR_CORRELATION_API = 'api/stress-analyses/correlations';

// api endpoint for live stress detection
export const STRESS_DETECTION_SERVICE_BOAT_API = 'api/stress-detections/service-boats';
export const STRESS_ENGINE_STATUS_API = 'api/stress/cstatus';
export const STRESS_ENGINE_START_API = 'api/stress/startstop/start';
export const STRESS_ENGINE_STOP_API = 'api/stress/startstop/stop';
export const STRESS_ENGINE_STRESS_LEVEL_API = 'static/data.txt';
export const STRESS_ENGINE_STREAMING_STATUS_API = 'api/stress/stream_status';

// api endpoint for live dead fish counting
export const DEAD_FISH_COUNTING_SITE_API = 'api/sites/dead-fish-counting-site-list';
export const DEAD_FISH_COUNTING_ENGINE_STATUS_API = 'api/deadfishcount/cstatus';
export const DEAD_FISH_COUNTING_ENGINE_START_API = 'api/deadfishcount/startstop/start';
export const DEAD_FISH_COUNTING_ENGINE_STOP_API = 'api/deadfishcount/startstop/stop';
export const DEAD_FISH_COUNTING_ENGINE_STREAMING_RESULT_API = 'api/deadfishcount/stream_result';

// api endpoint for flow
export const FLOW_REPORT_API = 'api/flow-detections/reports';

// api endpoint for process
export const PROCESS_API = 'api/processes';

export const COMPANY_CONFIG_API = 'api/companies/company-configs';
