import React from 'react';
import StressDetection from './StressDetection';
import DeadFishCounting from './DeadFishCounting';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LiveFishEvent = () => {
    const { showLiveDeadFishCounting, showLiveStressDetection } = useFlags();

    if (!showLiveDeadFishCounting && !showLiveStressDetection) {
        return;
    }

    return (
        <>
            {showLiveDeadFishCounting && <DeadFishCounting />}
            {showLiveStressDetection && <StressDetection />}
        </>
    );
};

export default LiveFishEvent;
