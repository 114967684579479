import { Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { deadFishCountingSitesSelector } from 'redux/selector';
import { resetDeadFishCountingSites, setDeadFishCountingStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getDeadFishCountingSites, startDeadFishCountingEngine } from 'redux/thunks';
import PenList from '../PenList';
import SiteStatus from './SiteStatus';
import { NOTIFICATION_SERVICE } from 'services';
import { useNavigate } from 'react-router-dom';
import { LIVE_DEAD_FISH_COUNTING_PAGE } from 'common/constants';
import { alertErrorMessage } from 'common/utils';

const DeadFishCounting = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [penListState, setPenListState] = useState({ isOpen: false, site: null });
    const { data: sites, loading } = useSelector(deadFishCountingSitesSelector);

    useEffect(() => {
        dispatch(getDeadFishCountingSites());

        return () => {
            dispatch(resetDeadFishCountingSites());
        };
    }, []);

    const siteGroups = useMemo(() => {
        const sitesWithEngineConfigAndBaseUrl = [];
        const sitesWithEngineConfig = [];
        const sitesWithoutEngineConfig = [];

        sites.forEach((site) => {
            if (site.hasEngineConfig && site.hasEngineBaseUrl) {
                sitesWithEngineConfigAndBaseUrl.push(site);
            } else if (site.hasEngineConfig) {
                sitesWithEngineConfig.push(site);
            } else {
                sitesWithoutEngineConfig.push(site);
            }
        });

        return [sitesWithEngineConfigAndBaseUrl, sitesWithEngineConfig, sitesWithoutEngineConfig];
    }, [sites]);

    const handlePenListClose = useCallback(() => {
        setPenListState({ isOpen: false, site: null });
    }, []);

    const handlePenListOpen = useCallback((site) => {
        setPenListState({ isOpen: true, site });
    }, []);

    const handleDeadFishCountingEngineStart = useCallback(
        async (penNumber) => {
            try {
                const response = await dispatch(
                    startDeadFishCountingEngine({
                        baseUrl: penListState.site?.engineBaseUrl,
                        penNumber,
                        siteName: penListState.site?.siteName,
                        localityNumber: penListState.site?.localityNumber
                    })
                ).unwrap();

                if (response.hasBeenStarting) {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveDeadFishCounting.notification.hasStartedCounting'),
                        duration: 15
                    });
                } else {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveDeadFishCounting.notification.startCounting'),
                        duration: 10
                    });
                }

                dispatch(
                    setDeadFishCountingStreaming({
                        isCounting: true,
                        penNumber,
                        engineBaseUrl: penListState.site?.engineBaseUrl,
                        siteId: penListState.site?.id,
                        siteName: penListState.site?.siteName,
                        localityNumber: penListState.site?.localityNumber
                    })
                );

                handlePenListClose();
                navigate(LIVE_DEAD_FISH_COUNTING_PAGE);
            } catch (err) {
                alertErrorMessage(err);
            }
        },
        [penListState.site, handlePenListClose]
    );

    return (
        <div className="counting-page">
            <div className="mt-10">
                <h2>{t('liveDeadFishCounting.siteStatus')}</h2>

                {loading ? (
                    <Spin />
                ) : sites.length ? (
                    siteGroups.map((sites, index) => (
                        <div key={index} className="flex flex-wrap gap-3 mb-3">
                            {sites.map((site) => (
                                <SiteStatus
                                    site={site}
                                    key={site.id}
                                    onPenListOpen={handlePenListOpen}
                                />
                            ))}
                        </div>
                    ))
                ) : (
                    <p className="m-0 text-base text-red-600">
                        {t('liveDeadFishCounting.noSiteAvailable')}
                    </p>
                )}
            </div>

            <PenList
                isOpen={penListState.isOpen}
                onClose={handlePenListClose}
                onEngineStart={(penNumber) => handleDeadFishCountingEngineStart(penNumber)}
            />
        </div>
    );
};

export default DeadFishCounting;
