import { Spin } from 'antd';
import { LIVE_STRESS_DETECTION_PAGE } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stressDetectionServiceBoatsSelector, userOrganizationIdSelector } from 'redux/selector';
import { resetStressDetectionServiceBoats, setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getServiceBoats, startStressEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import PenList from '../PenList';
import ServiceBoatStatus from './ServiceBoatStatus';

const StressDetection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [penListState, setPenListState] = useState({ isOpen: false, serviceBoat: null });

    const { data: serviceBoats, loading } = useSelector(stressDetectionServiceBoatsSelector);
    const userOrganizationId = useSelector(userOrganizationIdSelector);

    useEffect(() => {
        dispatch(getServiceBoats());

        return () => {
            dispatch(resetStressDetectionServiceBoats());
        };
    }, []);

    const serviceBoatGroups = useMemo(() => {
        const serviceBoatsWithEngineConfigAndBaseUrl = [];
        const serviceBoatsWithEngineConfig = [];
        const serviceBoatsWithoutEngineConfig = [];

        serviceBoats.forEach((site) => {
            if (site.hasEngineConfig && site.hasEngineBaseUrl) {
                serviceBoatsWithEngineConfigAndBaseUrl.push(site);
            } else if (site.hasEngineConfig) {
                serviceBoatsWithEngineConfig.push(site);
            } else {
                serviceBoatsWithoutEngineConfig.push(site);
            }
        });

        return [
            serviceBoatsWithEngineConfigAndBaseUrl,
            serviceBoatsWithEngineConfig,
            serviceBoatsWithoutEngineConfig
        ];
    }, [serviceBoats]);

    const handlePenListClose = useCallback(() => {
        setPenListState({ isOpen: false, serviceBoat: null });
    }, []);

    const handlePenListOpen = useCallback((serviceBoat) => {
        setPenListState({ isOpen: true, serviceBoat });
    }, []);

    const handleStressEngineStart = useCallback(
        async (penNumber) => {
            try {
                const response = await dispatch(
                    startStressEngine({
                        baseUrl: penListState.serviceBoat?.engineBaseUrl,
                        penNumber,
                        farmerOrganizationId: userOrganizationId
                    })
                ).unwrap();

                if (response.hasBeenStarting) {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveStressDetection.notification.hasStartedDetection'),
                        duration: 15
                    });
                } else {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveStressDetection.notification.startDetection'),
                        duration: 10
                    });
                }

                dispatch(
                    setStressDetectionStreaming({
                        isDetecting: true,
                        engineBaseUrl: penListState.serviceBoat?.engineBaseUrl,
                        serviceBoatId: penListState.serviceBoat?.id,
                        serviceBoatName: penListState.serviceBoat?.name,
                        penNumber
                    })
                );

                handlePenListClose();
                navigate(LIVE_STRESS_DETECTION_PAGE);
            } catch (err) {
                alertErrorMessage(err);
            }
        },
        [penListState.serviceBoat, userOrganizationId, handlePenListClose]
    );

    return (
        <div className="counting-page">
            <div className="mt-10">
                <h2>{t('liveStressDetection.serviceBoatStatus')}</h2>

                {loading ? (
                    <Spin />
                ) : serviceBoats.length ? (
                    serviceBoatGroups.map((group, index) => (
                        <div key={index} className="flex flex-wrap gap-3 mb-3">
                            {group.map((boat) => (
                                <ServiceBoatStatus
                                    serviceBoat={boat}
                                    key={boat.id}
                                    onPenListOpen={handlePenListOpen}
                                />
                            ))}
                        </div>
                    ))
                ) : (
                    <p className="m-0 text-base text-red-600">
                        {t('liveStressDetection.noServiceBoatAvailable')}
                    </p>
                )}
            </div>

            <PenList
                isOpen={penListState.isOpen}
                onClose={handlePenListClose}
                onEngineStart={(penNumber) => handleStressEngineStart(penNumber)}
            />
        </div>
    );
};

export default StressDetection;
